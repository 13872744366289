<template>
  <a-select v-model="selectedIds"
            ref="selectRef"
            :mode="mode"
            :allow-clear="allowClear"
            :show-search="true"
            :auto-clear-search-value="false"
            :get-popup-container="getContainer"
            :filter-option="false"
            :disabled="disabled"
            :placeholder="placeholder"
            class="select"
            @focus="resetSearch"
            @popupScroll="onScroll"
            @change="select"
            @search="onSearch">
    <a-select-option v-if="showAll"
                     :value="0"
                     @click="selectAll">
      全选
    </a-select-option>
    <a-select-option v-for="(item) in filteredOptions"
                     :key="item[options.id]"
                     :value="item[options.id]">
      {{ item[options.name] }}
    </a-select-option>
  </a-select>
</template>

<script>
import lodash from 'lodash';
import {
  getSelectList,
} from './api';

export default {
  name: 'SelectWithPager',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: { type: String },
    mode: {
      type: String,
      default: 'multiple',
      validator: (val) => ['multiple', 'default'].includes(val),
    },
    disabled: { type: Boolean, default: false },
    allowClear: { type: Boolean, default: true },
    allowSelectAll: { type: Boolean, default: true },
    placeholder: { type: String, default: '请选择' },
    list: { type: Array, default: () => [] },
    options: { type: Object, default: () => ({ id: 'id', name: 'name' }) },
    fixedTags: {
      type: Array, default: () => [
        'a-form-model-item',
        'AFormModelItem',
        'a-form-item',
        'AFormItem',
      ],
    },

    api: { type: String, default: '' },
    params: { type: Object, default: () => ({}) },
    pager: {
      type: Object,
      validator: (pager) => !pager || [
        'page',
        'perPage',
        'total',
      ].every((field) => field !== undefined && field !== null),
    },
    useLocalPager: { type: Boolean, default: true },
  },
  computed: {
    showAll() {
      return this.mode === 'multiple' && this.allowSelectAll && this.selectList?.length;
    },
    getContainer() {
      // 1. 下拉框跟随输入框滚动
      // 2. 当用于 form-model-item 时，保证 mode === 'multiple' 时，选中选项后，保证下拉框不消失
      return () => {
        let parent = this.$refs.selectRef.$parent;
        let count = 9999;
        while (
          parent
          && (!parent?.$vnode?.componentOptions?.tag || !this.fixedTags.includes(parent.$vnode.componentOptions.tag))
          && count > 0
          ) {
          parent = parent.$parent;
          count -= 1;
        }
        return (count > 0 ? parent?.$el : null) || this.$root?.$el || document.body || null;
      };
    },
    selectList() {
      return this.list?.length ? this.list : this.totalList;
    },
    selectedItems() {
      return this.selectList.filter(
        (i) => (
          this.mode === 'multiple' ? this.selectedIds : [this.selectedIds]
        ).includes(i.id),
      );
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.setSelectedIds();
      },
    },
    pager: {
      immediate: true,
      deep: true,
      handler(pager) {
        this.page = pager?.page || 1;
        this.perPage = pager?.perPage || 50;
        this.total = pager?.total || 0;
      },
    },
    selectedIds: {
      immediate: true,
      handler() {
        this.updateFilteredOptions();
      },
    },
    selectList: {
      immediate: true,
      handler() {
        this.updateFilteredOptions();
      },
    },
  },
  data() {
    return {
      selectedIds: [],
      isAllClicked: false,

      page: 1,
      perPage: 50,
      total: 0,

      filterTxt: '',
      totalList: [],

      filteredOptions: [],
    };
  },
  created() {
    if (this.api) {
      this.getList(1);
    }

    this.debounce = lodash.debounce(() => {
      this.emitSearch();
      if (this.api) {
        this.getList(1);
      }
    }, 500);
  },
  methods: {
    setSelectedIds(value) {
      value = value || this.value;

      if (!value) {
        this.selectedIds = this.mode === 'multiple' ? [] : undefined;
        return;
      }
      const ids = value ? value.split(',').map((i) => +i) : [];
      this.selectedIds = this.mode === 'multiple' ? ids : (ids[0] || undefined);
    },

    emitSearch() {
      this.$emit('search', this.filterTxt);
    },
    resetSearch() {
      this.filterTxt = '';
      this.emitSearch();
      this.getList(1);
    },
    onSearch(value) {
      this.filterTxt = value;
      this.debounce();
    },

    updateFilteredOptions() {
      if (!this.selectList?.length) {
        this.filteredOptions = [];
        return;
      }

      if (!this.useLocalPager) {
        this.filteredOptions = [...this.totalList];
      } else {
        this.getDataLocal(1);
      }

      if (this.mode === 'multiple' && !this.selectedIds?.length || !this.selectedIds) {
        return;
      }

      if (!this.useLocalPager) {
        this.filteredOptions = [...this.totalList];
      } else {
        this.getDataLocal(1);
      }

      const ids = this.mode === 'multiple' ? this.selectedIds : [this.selectedIds];
      const items = this.selectList.filter((i) => ids.includes(i.id));
      items.forEach((item) => {
        if (this.filteredOptions.findIndex((i) => i.id === item.id) === -1) {
          this.filteredOptions.unshift(item);
        }
      });
    },

    async getList(page) {
      if (!this.useLocalPager || page <= 1) {
        await this.getData(page);
      } else {
        this.getDataLocal(page);
      }
    },

    async getData(page) {
      if (!this.api || this.loading) {
        return;
      }
      this.loading = true;

      const params = {
        title: this.filterTxt,
        ...(this.params || {}),
      };
      if (!this.useLocalPager) {
        params.page = this.params?.page || page;
        params.per_page = this.params?.perPage || this.perPage;
      }

      const data = await getSelectList({
        url: this.api,
        params,
      }).finally(() => {
        this.loading = false;
      });

      this.page = page || 1;
      const result = data?.data || [];
      const totalList = page > 1 ? (this.totalList.concat(...result)) : result;

      this.total = data?.meta?.pagination?.total || 0;
      this.totalList = totalList;
    },
    getDataLocal(page) {
      this.filteredOptions = this.selectList.filter((option, index) => {
        if (!this.filterTxt && index < page * this.perPage) {
          return true;
        }
        return this.filterTxt && option.title.indexOf(this.filterTxt) > -1;
      });
    },

    onScroll(ev) {
      const target = ev.currentTarget || ev.target || ev.srcElement;
      if (!target) {
        return;
      }

      const height = target.clientHeight || 250;
      const scrollTop = target.scrollTop || 0;
      const scrollHeight = target.scrollHeight || 250;

      if (scrollTop + 1.2 * height > scrollHeight && this.page * this.perPage < this.total) {
        this.$emit('reach-bottom');
        this.loadMore();
      }
    },

    loadMore() {
      this.getList(this.page + 1);
    },

    selectAll() {
      this.isAllClicked = true;
    },

    select() {
      if (!this.filteredOptions?.length) {
        return;
      }

      if (this.isAllClicked && this.mode === 'multiple') {
        this.selectedIds = this.filteredOptions.map((op) => op[this.options.id]).filter((i) => !!i);
      }
      this.isAllClicked = false;

      const selectedIds = this.mode === 'multiple' ? this.selectedIds : [this.selectedIds];

      const ids = selectedIds.filter(i => !!i);
      this.$emit('change', ids.join(','), [...(this.selectedItems || [])]);
    },
  },
};
</script>

<style scoped lang="less">

</style>
